import { Component, OnInit } from "@angular/core";
import { UserService } from "./shared/user/user.service";
import { DataService } from "./shared/data/data.service";
import { FormControl, Validators } from "@angular/forms";
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  minLength = 4;
  name = new FormControl("", [
    Validators.required,
    Validators.minLength(this.minLength),
  ]);

  user: User;
  constructor(
    private userService: UserService,
    private dataService: DataService,
    private auth: AngularFireAuth
  ) {}

  ngOnInit() {
    this.auth.auth.signInAnonymously().then((auth) => {
      console.log("Logged in");
    });
    this.user = this.userService.user;
    this.userService.loginChanged.subscribe((user: User) => {
      this.user = user;
    });
  }

  logout() {
    this.userService.logout();
  }

  login() {
    if (this.name.value.length < this.minLength) {
      return;
    }
    const userName = this.name.value;
    this.dataService.getUser(userName).subscribe((user) => {
      if (user === null) {
        console.log("Creating user...");
        this.dataService.createUser(userName).subscribe((createdUser) => {
          this.userService.login(createdUser);
        });
      } else {
        this.userService.login(user);
      }
    });
    setTimeout(() => {
      this.name.setValue("");
    }, 1000);
  }
}
