import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CalendarComponent } from "./calendar/calendar.component";
import { TileComponent } from "./tile/tile.component";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { MaterialModule } from "./shared/material.module";
import { ReactiveFormsModule } from "@angular/forms";
import { BlockedEntryDialogComponent } from "./blocked-entry-dialog/blocked-entry-dialog.component";

@NgModule({
  declarations: [
    AppComponent,
    CalendarComponent,
    TileComponent,
    BlockedEntryDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [BlockedEntryDialogComponent],
})
export class AppModule {}
