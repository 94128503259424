import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user/user.service';
import { DataService } from '../shared/data/data.service';
import { DateService } from '../shared/date/date.service';
import { SlideInAnimation } from '../animations/animations';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [SlideInAnimation]
})
export class CalendarComponent implements OnInit {
  calendar: CalendarEntry[];
  user: User;
  constructor(private userService: UserService, private dataService: DataService, private dateService: DateService) { }

  ngOnInit() {
    this.user = this.userService.user;
    this.userService.loginChanged.subscribe((user: User) => {
      this.user = user;
    });
    this.dataService.getCalendar().subscribe(calendar => {
      this.calendar = calendar;

      let dayDifference = this.dateService.getDayDifference();

      // dayDifference = 24;

      this.shuffle();
      this.blockEntries(dayDifference);
    });
  }

  shuffle() {
    for (let i = 0; i < this.calendar.length; i++) {
      const j = Math.floor(Math.random() * i);
      const temp = this.calendar[i];
      this.calendar[i] = this.calendar[j];
      this.calendar[j] = temp;
    }
    return this.calendar;
  }

  blockEntries(dayDifference: number) {
    for (const entry of this.calendar) {
      if (entry.id + 1 <= dayDifference) {
        entry.blocked = false;
      } else {
        entry.blocked = true;
      }
    }
  }

  cardTurned(entry: CalendarEntry) {
    this.dataService.openTile(this.user, entry.id);
  }
}
