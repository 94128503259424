import { trigger, state, style, transition, animate, group, query, stagger } from '@angular/animations';

export const SlideInAnimation = [
  trigger('slideIn', [
    transition('* => *', [
      query('app-tile', style({ transform: 'translateY(550%)' })),
      query('app-tile',
        stagger('100ms', [
          animate('200ms', style({ transform: 'translateY(0)' }))
        ]))
    ])
  ]),
];

export const FlipAnimation = [
  trigger('flipState', [
    state('active', style({
      transform: 'rotateY(179deg)'
    })),
    state('inactive', style({
      transform: 'rotateY(0)'
    })),
    transition('active => inactive', animate('500ms ease-out')),
    transition('inactive => active', animate('500ms ease-in'))
  ])
];
