import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FlipAnimation } from '../animations/animations';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BlockedEntryDialogComponent } from '../blocked-entry-dialog/blocked-entry-dialog.component';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  animations: [FlipAnimation]
})
export class TileComponent implements OnInit {
  @Output() cardTurned: EventEmitter<CalendarEntry> = new EventEmitter<CalendarEntry>();
  @Input() entry: CalendarEntry;
  @Input() user: User;

  turn = false;
  flip = 'inactive';
  animationState = 'out';
  backgroundImage;

  constructor(public dialog: MatDialog, private sanitization: DomSanitizer) { }

  ngOnInit() {
    if (this.user.days[this.entry.id]) {
      setTimeout(() => { this.turn = true; }, 250);
      this.flipy();
    }
    this.entry.image = + this.entry.id + '.jpg';
    this.backgroundImage = this.sanitization.sanitize(2, 'url(/assets/pictures/' + this.entry.image + ');');

  }

  // style = "background-image: "

  openDialog(): void {
    const dialogRef = this.dialog.open(BlockedEntryDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed successfully');
    });
  }

  flipy() {
    this.flip = 'active';
  }

  turnCard() {
    if (!this.entry.blocked) {
      setTimeout(() => { this.turn = true; }, 250);
      this.flipy();
      this.cardTurned.emit(this.entry);
    } else {
      this.openDialog();
    }
  }
}
