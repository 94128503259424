import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  getDayDifference(): number {
    const date1 = new Date(new Date().getFullYear(), 11, 1);
    const date2 = new Date();
    const diffTime = date2.getTime() - date1.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
}
