import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UserService } from '../shared/user/user.service';

@Component({
  selector: 'app-blocked-entry-dialog',
  templateUrl: './blocked-entry-dialog.component.html'
})
export class BlockedEntryDialogComponent implements OnInit {
  user: User;
  currentDay: number;
  currentMonth: number;
  constructor(
    public dialogRef: MatDialogRef<BlockedEntryDialogComponent>, private userService: UserService) { }

  ngOnInit() {
    this.user = this.userService.user;
    this.currentDay = new Date().getDate();
    this.currentMonth = new Date().getMonth() + 1;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
