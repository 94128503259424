import { Injectable, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserService {
  user: User = null;
  loginChanged: EventEmitter<User> = new EventEmitter();

  constructor() {}

  login(user: User) {
    this.user = user;
    this.loginChanged.emit(user);
  }

  logout() {
    this.user = null;
    this.loginChanged.emit(null);
  }
}
