import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private CALENDAR = 'calendar';
  private USERS = 'users';


  private calendar: AngularFirestoreCollection<CalendarEntry>;
  private users: AngularFirestoreCollection<User>;

  constructor(private db: AngularFirestore) {
    this.calendar = db.collection<CalendarEntry>(this.CALENDAR);
    this.users = db.collection<User>(this.USERS);
  }

  getCalendar(): Observable<CalendarEntry[]> {
    return this.db.collection<CalendarEntry>(this.CALENDAR, ref =>
      ref.orderBy('id', 'asc'))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as CalendarEntry;
          // data.id = +a.payload.doc.id;
          data.id = +data.id;
          return data;
        })));
  }

  getUser(id: string): Observable<User> {
    return this.users.doc(`${id}`).snapshotChanges()
      .pipe(map(a => {
        if (a.payload.data() != null) {
          const data = a.payload.data() as User;
          data.id = a.payload.id;
          return data;
        }
        return null;
      }));
  }

  createUser(user: string): Observable<User> {
    const days = [];
    for (let i = 0; i < 24; i++) {
      days.push(false);
    }
    this.users.doc(user).set({ days });
    return this.getUser(user);
  }

  getUsers(): Observable<User[]> {
    return this.users.snapshotChanges()
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data() as User;
        data.id = a.payload.doc.id;
        return data;
      })));
  }

  // Update user on tile x
  openTile(user: User, day: number): User {
    user.days[day] = true;
    this.users.doc(user.id).set({ days: user.days });
    return user;
  }
}
